<template>
    <div class="left-content" style="width:100%">
        <div class="type-list">
            <span>{{Number($route.query.type)===0?'集团动态':'通知公告'}}</span>
        </div>
        <div class="blank10"/>
        <a v-for="(item,index) in list" :key="index" :title="item.title" :href="`/detail?id=${item.id}`"><i class="fa icon-arrow-right"></i> {{item.title}}<span>{{item.create_time}}</span></a>
        <el-pagination
            v-if="Number(total)!==0"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="Number(total)"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>
<script>
export default {
    props:{
        type:{
            type:Number,
            defalut:() => 1,
        }
    },
    data() {
        return {
            typeName:[
                {title:'考试通知',type:1},//1
                {title:'名企招聘',type:3},
                {title:'国家/地方政策',type:0},
                {title:'行业新闻',type:2},
            ],
            total:0,
            list:[],
            page:1,
            limit:10,
        }
    },
    beforeMount() {
        if(this.$route.query.type){
            this.type = this.$route.query.type;
            this.getData();
        }
    },
    methods: {
        slectType(type){
            this.type = type;
            this.page = 1;
            this.limit = 10;
            this.getData();
        },
        handleSizeChange(size){
            this.limit = size;
            this.getData();
        },
        handleCurrentChange(page){
            this.page = page;
            this.getData();
        },
        async getData(){
            const params = {
                page:this.page,
                limit: this.limit,
                type: Number(this.type),
            }
            const res = await this.$http.get('/api/index/getNewsList',params);
            if(res){
                this.list = res.list;
                this.total = res.total;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.el-pagination{
    color:#000;
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
    span{
        font-size: 16px !important;
    }
    span:not([class*=suffix]){
        font-size: 19px !important;
    }
}
.type-list{
    display: flex;
    flex-direction: row;
    span{
        display: block;
        cursor: pointer;
        height: 49px;
        line-height: 49px;
        font-size: 20px;
        color: #333;
        margin-right: 20px;
        padding: 0 10px;
        font-weight: 400;
    }
    .select{
        font-weight: bold;
        border-bottom: 2px solid #224899;
        color: #224899;
    }
    span:hover{
        color:#224899;
    }
}
a {
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    display: block;
    span {
        font-size: 14px;
        float: right;
        color: #999;
    }
}
a:hover{
    color:#326fd4
}
</style>
