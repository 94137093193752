<template>
    <div class="info-list">
        <Header/>
        <div class="blank20"/>
        <div class="main-page-content contain-left-right">
            <LeftContent :type="type"/>
        </div>
        <div class="blank20"/>
        <Footer/>
    </div>
</template>
<script>
import LeftContent from './components/leftListContent';
export default {
    data() {
        return {
            type:1,
        }
    },
    components:{
        LeftContent,
    },
    beforeMount() {
        if(this.$route.query.type){
            this.type = Number(this.$route.query.type);
        }
    },
}
</script>
<style lang="scss" scoped>

</style>
